// source: https://github.com/astockwell/countries-and-provinces-states-regions/tree/master/countries

const US_STATES = [
  {code: 'AL', name: 'Alabama'},
  {code: 'AK', name: 'Alaska'},
  {code: 'AZ', name: 'Arizona'},
  {code: 'AR', name: 'Arkansas'},
  {code: 'CA', name: 'California'},
  {code: 'CO', name: 'Colorado'},
  {code: 'CT', name: 'Connecticut'},
  {code: 'DE', name: 'Delaware'},
  {code: 'FL', name: 'Florida'},
  {code: 'GA', name: 'Georgia'},
  {code: 'HI', name: 'Hawaii'},
  {code: 'ID', name: 'Idaho'},
  {code: 'IL', name: 'Illinois'},
  {code: 'IN', name: 'Indiana'},
  {code: 'IA', name: 'Iowa'},
  {code: 'KS', name: 'Kansas'},
  {code: 'KY', name: 'Kentucky'},
  {code: 'LA', name: 'Louisiana'},
  {code: 'ME', name: 'Maine'},
  {code: 'MD', name: 'Maryland'},
  {code: 'MA', name: 'Massachusetts'},
  {code: 'MI', name: 'Michigan'},
  {code: 'MN', name: 'Minnesota'},
  {code: 'MS', name: 'Mississippi'},
  {code: 'MO', name: 'Missouri'},
  {code: 'MT', name: 'Montana'},
  {code: 'NE', name: 'Nebraska'},
  {code: 'NV', name: 'Nevada'},
  {code: 'NH', name: 'New Hampshire'},
  {code: 'NJ', name: 'New Jersey'},
  {code: 'NM', name: 'New Mexico'},
  {code: 'NY', name: 'New York'},
  {code: 'NC', name: 'North Carolina'},
  {code: 'ND', name: 'North Dakota'},
  {code: 'OH', name: 'Ohio'},
  {code: 'OK', name: 'Oklahoma'},
  {code: 'OR', name: 'Oregon'},
  {code: 'PA', name: 'Pennsylvania'},
  {code: 'RI', name: 'Rhode Island'},
  {code: 'SC', name: 'South Carolina'},
  {code: 'SD', name: 'South Dakota'},
  {code: 'TN', name: 'Tennessee'},
  {code: 'TX', name: 'Texas'},
  {code: 'UT', name: 'Utah'},
  {code: 'VT', name: 'Vermont'},
  {code: 'VA', name: 'Virginia'},
  {code: 'WA', name: 'Washington'},
  {code: 'WV', name: 'West Virginia'},
  {code: 'WI', name: 'Wisconsin'},
  {code: 'WY', name: 'Wyoming'},
];

const CANADA_STATES = [
  {code: 'AB', name: 'Alberta'},
  {code: 'BC', name: 'British Columbia'},
  {code: 'MB', name: 'Manitoba'},
  {code: 'NB', name: 'New Brunswick'},
  {code: 'NL', name: 'Newfoundland and Labrador'},
  {code: 'NT', name: 'Northwest Territories'},
  {code: 'NS', name: 'Nova Scotia'},
  {code: 'NU', name: 'Nunavut'},
  {code: 'ON', name: 'Ontario'},
  {code: 'PE', name: 'Prince Edward Island'},
  {code: 'QC', name: 'Quebec'},
  {code: 'SK', name: 'Saskatchewan'},
  {code: 'YT', name: 'Yukon'}
];

const UK_STATES = [
  {code: 'LND', name: 'London, City of'},
  {code: 'ABE', name: 'Aberdeen City'},
  {code: 'ABD', name: 'Aberdeenshire'},
  {code: 'ANS', name: 'Angus'},
  {code: 'AGB', name: 'Argyll and Bute'},
  {code: 'CLK', name: 'Clackmannanshire'},
  {code: 'DGY', name: 'Dumfries and Galloway'},
  {code: 'DND', name: 'Dundee City'},
  {code: 'EAY', name: 'East Ayrshire'},
  {code: 'EDU', name: 'East Dunbartonshire'},
  {code: 'ELN', name: 'East Lothian'},
  {code: 'ERW', name: 'East Renfrewshire'},
  {code: 'EDH', name: 'Edinburgh, City of'},
  {code: 'ELS', name: 'Eilean Siar'},
  {code: 'FAL', name: 'Falkirk'},
  {code: 'FIF', name: 'Fife'},
  {code: 'GLG', name: 'Glasgow City'},
  {code: 'HLD', name: 'Highland'},
  {code: 'IVC', name: 'Inverclyde'},
  {code: 'MLN', name: 'Midlothian'},
  {code: 'MRY', name: 'Moray'},
  {code: 'NAY', name: 'North Ayrshire'},
  {code: 'NLK', name: 'North Lanarkshire'},
  {code: 'ORK', name: 'Orkney Islands'},
  {code: 'PKN', name: 'Perth and Kinross'},
  {code: 'RFW', name: 'Renfrewshire'},
  {code: 'SCB', name: 'Scottish Borders'},
  {code: 'ZET', name: 'Shetland Islands'},
  {code: 'SAY', name: 'South Ayrshire'},
  {code: 'SLK', name: 'South Lanarkshire'},
  {code: 'STG', name: 'Stirling'},
  {code: 'WDU', name: 'West Dunbartonshire'},
  {code: 'WLN', name: 'West Lothian'},
  {code: 'ANN', name: 'Antrim and Newtownabbey'},
  {code: 'AND', name: 'Ards and North Down'},
  {code: 'ABC', name: 'Armagh City, Banbridge and Craigavon'},
  {code: 'BFS', name: 'Belfast City'},
  {code: 'CCG', name: 'Causeway Coast and Glens'},
  {code: 'DRS', name: 'Derry and Strabane'},
  {code: 'FMO', name: 'Fermanagh and Omagh'},
  {code: 'LBC', name: 'Lisburn and Castlereagh'},
  {code: 'MEA', name: 'Mid and East Antrim'},
  {code: 'MUL', name: 'Mid-Ulster'},
  {code: 'NMD', name: 'Newry, Mourne and Down'},
  {code: 'BDG', name: 'Barking and Dagenham'},
  {code: 'BNE', name: 'Barnet'},
  {code: 'BEX', name: 'Bexley'},
  {code: 'BEN', name: 'Brent'},
  {code: 'BRY', name: 'Bromley'},
  {code: 'CMD', name: 'Camden'},
  {code: 'CRY', name: 'Croydon'},
  {code: 'EAL', name: 'Ealing'},
  {code: 'ENF', name: 'Enfield'},
  {code: 'GRE', name: 'Greenwich'},
  {code: 'HCK', name: 'Hackney'},
  {code: 'HMF', name: 'Hammersmith and Fulham'},
  {code: 'HRY', name: 'Haringey'},
  {code: 'HRW', name: 'Harrow'},
  {code: 'HAV', name: 'Havering'},
  {code: 'HIL', name: 'Hillingdon'},
  {code: 'HNS', name: 'Hounslow'},
  {code: 'ISL', name: 'Islington'},
  {code: 'KEC', name: 'Kensington and Chelsea'},
  {code: 'KTT', name: 'Kingston upon Thames'},
  {code: 'LBH', name: 'Lambeth'},
  {code: 'LEW', name: 'Lewisham'},
  {code: 'MRT', name: 'Merton'},
  {code: 'NWM', name: 'Newham'},
  {code: 'RDB', name: 'Redbridge'},
  {code: 'RIC', name: 'Richmond upon Thames'},
  {code: 'SWK', name: 'Southwark'},
  {code: 'STN', name: 'Sutton'},
  {code: 'TWH', name: 'Tower Hamlets'},
  {code: 'WFT', name: 'Waltham Forest'},
  {code: 'WND', name: 'Wandsworth'},
  {code: 'WSM', name: 'Westminster'},
  {code: 'BNS', name: 'Barnsley'},
  {code: 'BIR', name: 'Birmingham'},
  {code: 'BOL', name: 'Bolton'},
  {code: 'BRD', name: 'Bradford'},
  {code: 'BUR', name: 'Bury'},
  {code: 'CLD', name: 'Calderdale'},
  {code: 'COV', name: 'Coventry'},
  {code: 'DNC', name: 'Doncaster'},
  {code: 'DUD', name: 'Dudley'},
  {code: 'GAT', name: 'Gateshead'},
  {code: 'KIR', name: 'Kirklees'},
  {code: 'KWL', name: 'Knowsley'},
  {code: 'LDS', name: 'Leeds'},
  {code: 'LIV', name: 'Liverpool'},
  {code: 'MAN', name: 'Manchester'},
  {code: 'NET', name: 'Newcastle upon Tyne'},
  {code: 'NTY', name: 'North Tyneside'},
  {code: 'OLD', name: 'Oldham'},
  {code: 'RCH', name: 'Rochdale'},
  {code: 'ROT', name: 'Rotherham'},
  {code: 'SLF', name: 'Salford'},
  {code: 'SAW', name: 'Sandwell'},
  {code: 'SFT', name: 'Sefton'},
  {code: 'SHF', name: 'Sheffield'},
  {code: 'SOL', name: 'Solihull'},
  {code: 'STY', name: 'South Tyneside'},
  {code: 'SHN', name: 'St. Helens'},
  {code: 'SKP', name: 'Stockport'},
  {code: 'SND', name: 'Sunderland'},
  {code: 'TAM', name: 'Tameside'},
  {code: 'TRF', name: 'Trafford'},
  {code: 'WKF', name: 'Wakefield'},
  {code: 'WLL', name: 'Walsall'},
  {code: 'WGN', name: 'Wigan'},
  {code: 'WRL', name: 'Wirral'},
  {code: 'WLV', name: 'Wolverhampton'},
  {code: 'BKM', name: 'Buckinghamshire'},
  {code: 'CAM', name: 'Cambridgeshire'},
  {code: 'CMA', name: 'Cumbria'},
  {code: 'DBY', name: 'Derbyshire'},
  {code: 'DEV', name: 'Devon'},
  {code: 'DOR', name: 'Dorset'},
  {code: 'ESX', name: 'East Sussex'},
  {code: 'ESS', name: 'Essex'},
  {code: 'GLS', name: 'Gloucestershire'},
  {code: 'HAM', name: 'Hampshire'},
  {code: 'HRT', name: 'Hertfordshire'},
  {code: 'KEN', name: 'Kent'},
  {code: 'LAN', name: 'Lancashire'},
  {code: 'LEC', name: 'Leicestershire'},
  {code: 'LIN', name: 'Lincolnshire'},
  {code: 'NFK', name: 'Norfolk'},
  {code: 'NYK', name: 'North Yorkshire'},
  {code: 'NTH', name: 'Northamptonshire'},
  {code: 'NTT', name: 'Nottinghamshire'},
  {code: 'OXF', name: 'Oxfordshire'},
  {code: 'SOM', name: 'Somerset'},
  {code: 'STS', name: 'Staffordshire'},
  {code: 'SFK', name: 'Suffolk'},
  {code: 'SRY', name: 'Surrey'},
  {code: 'WAR', name: 'Warwickshire'},
  {code: 'WSX', name: 'West Sussex'},
  {code: 'WOR', name: 'Worcestershire'},
  {code: 'BAS', name: 'Bath and North East Somerset'},
  {code: 'BDF', name: 'Bedford'},
  {code: 'BBD', name: 'Blackburn with Darwen'},
  {code: 'BPL', name: 'Blackpool'},
  {code: 'BGW', name: 'Blaenau Gwent'},
  {code: 'BCP', name: 'Bournemouth, Christchurch and Poole'},
  {code: 'BRC', name: 'Bracknell Forest'},
  {code: 'BGE', name: 'Bridgend [Pen-y-bont ar Ogwr GB-POG]'},
  {code: 'BNH', name: 'Brighton and Hove'},
  {code: 'BST', name: 'Bristol, City of'},
  {code: 'CAY', name: 'Caerphilly [Caerffili GB-CAF]'},
  {code: 'CRF', name: 'Cardiff [Caerdydd GB-CRD]'},
  {code: 'CMN', name: 'Carmarthenshire [Sir Gaerfyrddin GB-GFY]'},
  {code: 'CBF', name: 'Central Bedfordshire'},
  {code: 'CGN', name: 'Ceredigion [Sir Ceredigion]'},
  {code: 'CHE', name: 'Cheshire East'},
  {code: 'CHW', name: 'Cheshire West and Chester'},
  {code: 'CWY', name: 'Conwy'},
  {code: 'CON', name: 'Cornwall'},
  {code: 'DAL', name: 'Darlington'},
  {code: 'DEN', name: 'Denbighshire [Sir Ddinbych GB-DDB]'},
  {code: 'DER', name: 'Derby'},
  {code: 'DUR', name: 'Durham, County'},
  {code: 'ERY', name: 'East Riding of Yorkshire'},
  {code: 'FLN', name: 'Flintshire [Sir y Fflint GB-FFL]'},
  {code: 'GWN', name: 'Gwynedd'},
  {code: 'HAL', name: 'Halton'},
  {code: 'HPL', name: 'Hartlepool'},
  {code: 'HEF', name: 'Herefordshire'},
  {code: 'AGY', name: 'Isle of Anglesey [Sir Ynys Môn GB-YNM]'},
  {code: 'IOW', name: 'Isle of Wight'},
  {code: 'IOS', name: 'Isles of Scilly'},
  {code: 'KHL', name: 'Kingston upon Hull'},
  {code: 'LCE', name: 'Leicester'},
  {code: 'LUT', name: 'Luton'},
  {code: 'MDW', name: 'Medway'},
  {code: 'MTY', name: 'Merthyr Tydfil [Merthyr Tudful GB-MTU]'},
  {code: 'MDB', name: 'Middlesbrough'},
  {code: 'MIK', name: 'Milton Keynes'},
  {code: 'MON', name: 'Monmouthshire [Sir Fynwy GB-FYN]'},
  {code: 'NTL', name: 'Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]'},
  {code: 'NWP', name: 'Newport [Casnewydd GB-CNW]'},
  {code: 'NEL', name: 'North East Lincolnshire'},
  {code: 'NLN', name: 'North Lincolnshire'},
  {code: 'NSM', name: 'North Somerset'},
  {code: 'NBL', name: 'Northumberland'},
  {code: 'NGM', name: 'Nottingham'},
  {code: 'PEM', name: 'Pembrokeshire [Sir Benfro GB-BNF]'},
  {code: 'PTE', name: 'Peterborough'},
  {code: 'PLY', name: 'Plymouth'},
  {code: 'POR', name: 'Portsmouth'},
  {code: 'POW', name: 'Powys'},
  {code: 'RDG', name: 'Reading'},
  {code: 'RCC', name: 'Redcar and Cleveland'},
  {code: 'RCT', name: 'Rhondda Cynon Taff [Rhondda CynonTaf]'},
  {code: 'RUT', name: 'Rutland'},
  {code: 'SHR', name: 'Shropshire'},
  {code: 'SLG', name: 'Slough'},
  {code: 'SGC', name: 'South Gloucestershire'},
  {code: 'STH', name: 'Southampton'},
  {code: 'SOS', name: 'Southend-on-Sea'},
  {code: 'STT', name: 'Stockton-on-Tees'},
  {code: 'STE', name: 'Stoke-on-Trent'},
  {code: 'SWA', name: 'Swansea [Abertawe GB-ATA]'},
  {code: 'SWD', name: 'Swindon'},
  {code: 'TFW', name: 'Telford and Wrekin'},
  {code: 'THR', name: 'Thurrock'},
  {code: 'TOB', name: 'Torbay'},
  {code: 'TOF', name: 'Torfaen [Tor-faen]'},
  {code: 'VGL', name: 'Vale of Glamorgan, The [Bro Morgannwg GB-BMG]'},
  {code: 'WRT', name: 'Warrington'},
  {code: 'WBK', name: 'West Berkshire'},
  {code: 'WIL', name: 'Wiltshire'},
  {code: 'WNM', name: 'Windsor and Maidenhead'},
  {code: 'WOK', name: 'Wokingham'},
  {code: 'WRX', name: 'Wrexham [Wrecsam GB-WRC]'},
  {code: 'YOR', name: 'York'}
];

const IRELAND_STATES = [
  {code: 'C', name: 'Connacht'},
  {code: 'CW', name: 'County Carlow'},
  {code: 'CN', name: 'County Cavan'},
  {code: 'CE', name: 'County Clare'},
  {code: 'CO', name: 'County Cork'},
  {code: 'DL', name: 'County Donegal'},
  {code: 'D', name: 'County Dublin'},
  {code: 'G', name: 'County Galway'},
  {code: 'KY', name: 'County Kerry'},
  {code: 'KE', name: 'County Kildare'},
  {code: 'KK', name: 'County Kilkenny'},
  {code: 'LS', name: 'County Laois'},
  {code: 'LK', name: 'County Limerick'},
  {code: 'LD', name: 'County Longford'},
  {code: 'LH', name: 'County Louth'},
  {code: 'MO', name: 'County Mayo'},
  {code: 'MH', name: 'County Meath'},
  {code: 'MN', name: 'County Monaghan'},
  {code: 'OY', name: 'County Offaly'},
  {code: 'RN', name: 'County Roscommon'},
  {code: 'SO', name: 'County Sligo'},
  {code: 'TA', name: 'County Tipperary'},
  {code: 'WD', name: 'County Waterford'},
  {code: 'WH', name: 'County Westmeath'},
  {code: 'WX', name: 'County Wexford'},
  {code: 'WW', name: 'County Wicklow'},
  {code: 'L', name: 'Leinster'},
  {code: 'M', name: 'Munster'},
  {code: 'U', name: 'Ulster'}
];

const GERMANY_STATES = [
  {
		"name": "Baden-Württemberg",
		"code": "BW"
	},
	{
		"name": "Bayern",
		"code": "BY"
	},
	{
		"name": "Berlin",
		"code": "BE"
	},
	{
		"name": "Brandenburg",
		"code": "BB"
	},
	{
		"name": "Bremen",
		"code": "HB"
	},
	{
		"name": "Hamburg",
		"code": "HH"
	},
	{
		"name": "Hessen",
		"code": "HE"
	},
	{
		"name": "Mecklenburg-Vorpommern",
		"code": "MV"
	},
	{
		"name": "Niedersachsen",
		"code": "NI"
	},
	{
		"name": "Nordrhein-Westfalen",
		"code": "NW"
	},
	{
		"name": "Rheinland-Pfalz",
		"code": "RP"
	},
	{
		"name": "Saarland",
		"code": "SL"
	},
	{
		"name": "Sachsen",
		"code": "SN"
	},
	{
		"name": "Sachsen-Anhalt",
		"code": "ST"
	},
	{
		"name": "Schleswig-Holstein",
		"code": "SH"
	},
	{
		"name": "Thüringen",
		"code": "TH"
	}
];

const NETHERLANDS_STATES = [
  {
		"name": "Drenthe",
		"code": "DR"
	},
	{
		"name": "Flevoland",
		"code": "FL"
	},
	{
		"name": "Fryslân",
		"code": "FR"
	},
	{
		"name": "Gelderland",
		"code": "GE"
	},
	{
		"name": "Groningen",
		"code": "GR"
	},
	{
		"name": "Limburg",
		"code": "LI"
	},
	{
		"name": "Noord-Brabant",
		"code": "NB"
	},
	{
		"name": "Noord-Holland",
		"code": "NH"
	},
	{
		"name": "Overijssel",
		"code": "OV"
	},
	{
		"name": "Utrecht",
		"code": "UT"
	},
	{
		"name": "Zeeland",
		"code": "ZE"
	},
	{
		"name": "Zuid-Holland",
		"code": "ZH"
	}
];

const BELGIUM_STATES = [
  {
		"name": "Brussels Hoofdstedelijk Gewest",
		"code": "BRU"
	},
	{
		"name": "Région Wallonne",
		"code": "WAL"
	},
	{
		"name": "Vlaams Gewest",
		"code": "VLG"
	}
];

const FRANCE_STATES = [
  {
		"code": "A",
		"name": "Alsace"
	},
	{
		"code": "B",
		"name": "Aquitaine"
	},
	{
		"code": "C",
		"name": "Auvergne"
	},
	{
		"code": "E",
		"name": "Bretagne"
	},
	{
		"code": "D",
		"name": "Bourgogne"
	},
	{
		"code": "F",
		"name": "Centre"
	},
	{
		"code": "G",
		"name": "Champagne-Ardenne"
	},
	{
		"code": "H",
		"name": "Corse"
	},
	{
		"code": "I",
		"name": "Franche-Comté"
	},
	{
		"code": "K",
		"name": "Languedoc-Roussillon"
	},
	{
		"code": "L",
		"name": "Limousin"
	},
	{
		"code": "M",
		"name": "Lorraine"
	},
	{
		"code": "P",
		"name": "Basse-Normandie"
	},
	{
		"code": "N",
		"name": "Midi-Pyrénées"
	},
	{
		"code": "O",
		"name": "Nord-Pas-de-Calais"
	},
	{
		"code": "R",
		"name": "Pays de la Loire"
	},
	{
		"code": "S",
		"name": "Picardie"
	},
	{
		"code": "T",
		"name": "Poitou-Charentes"
	},
	{
		"code": "U",
		"name": "Provence-Alpes-Côte d'Azur"
	},
	{
		"code": "V",
		"name": "Rhône-Alpes"
	},
	{
		"code": "Q",
		"name": "Haute-Normandie"
	},
	{
		"code": "J",
		"name": "Île-de-France"
	}
];

const HUNGARY_STATES = [
  {
		"name": "Baranya",
		"code": "BA"
	},
	{
		"name": "Borsod-Abaúj-Zemplén",
		"code": "BZ"
	},
	{
		"name": "Budapest",
		"code": "BU"
	},
	{
		"name": "Bács-Kiskun",
		"code": "BK"
	},
	{
		"name": "Békés",
		"code": "BE"
	},
	{
		"name": "Békéscsaba",
		"code": "BC"
	},
	{
		"name": "Csongrád",
		"code": "CS"
	},
	{
		"name": "Debrecen",
		"code": "DE"
	},
	{
		"name": "Dunaújváros",
		"code": "DU"
	},
	{
		"name": "Eger",
		"code": "EG"
	},
	{
		"name": "Fejér",
		"code": "FE"
	},
	{
		"name": "Győr",
		"code": "GY"
	},
	{
		"name": "Győr-Moson-Sopron",
		"code": "GS"
	},
	{
		"name": "Hajdú-Bihar",
		"code": "HB"
	},
	{
		"name": "Heves",
		"code": "HE"
	},
	{
		"name": "Hódmezővásárhely",
		"code": "HV"
	},
	{
		"name": "Jász-Nagykun-Szolnok",
		"code": "JN"
	},
	{
		"name": "Kaposvár",
		"code": "KV"
	},
	{
		"name": "Kecskemét",
		"code": "KM"
	},
	{
		"name": "Komárom-Esztergom",
		"code": "KE"
	},
	{
		"name": "Miskolc",
		"code": "MI"
	},
	{
		"name": "Nagykanizsa",
		"code": "NK"
	},
	{
		"name": "Nyíregyháza",
		"code": "NY"
	},
	{
		"name": "Nógrád",
		"code": "NO"
	},
	{
		"name": "Pest",
		"code": "PE"
	},
	{
		"name": "Pécs",
		"code": "PS"
	},
	{
		"name": "Salgótarján",
		"code": "ST"
	},
	{
		"name": "Somogy",
		"code": "SO"
	},
	{
		"name": "Sopron",
		"code": "SN"
	},
	{
		"name": "Szabolcs-Szatmár-Bereg",
		"code": "SZ"
	},
	{
		"name": "Szeged",
		"code": "SD"
	},
	{
		"name": "Szekszárd",
		"code": "SS"
	},
	{
		"name": "Szolnok",
		"code": "SK"
	},
	{
		"name": "Szombathely",
		"code": "SH"
	},
	{
		"name": "Székesfehérvár",
		"code": "SF"
	},
	{
		"name": "Tatabánya",
		"code": "TB"
	},
	{
		"name": "Tolna",
		"code": "TO"
	},
	{
		"name": "Vas",
		"code": "VA"
	},
	{
		"name": "Veszprém",
		"code": "VE"
	},
	{
		"name": "Veszprém",
		"code": "VM"
	},
	{
		"name": "Zala",
		"code": "ZA"
	},
	{
		"name": "Zalaegerszeg",
		"code": "ZE"
	},
	{
		"name": "Érd",
		"code": "ER"
	}
];

const SPAIN_STATES = [
  {
		"name": "A Coruña",
		"code": "C"
	},
	{
		"name": "Albacete",
		"code": "AB"
	},
	{
		"name": "Alicante",
		"code": "A"
	},
	{
		"name": "Almería",
		"code": "AL"
	},
	{
		"name": "Asturias",
		"code": "O"
	},
	{
		"name": "Badajoz",
		"code": "BA"
	},
	{
		"name": "Balears",
		"code": "PM"
	},
	{
		"name": "Barcelona",
		"code": "B"
	},
	{
		"name": "Burgos",
		"code": "BU"
	},
	{
		"name": "Cantabria",
		"code": "S"
	},
	{
		"name": "Castellón",
		"code": "CS"
	},
	{
		"name": "Ciudad Real",
		"code": "CR"
	},
	{
		"name": "Cuenca",
		"code": "CU"
	},
	{
		"name": "Cáceres",
		"code": "CC"
	},
	{
		"name": "Cádiz",
		"code": "CA"
	},
	{
		"name": "Córdoba",
		"code": "CO"
	},
	{
		"name": "Girona",
		"code": "GI"
	},
	{
		"name": "Granada",
		"code": "GR"
	},
	{
		"name": "Guadalajara",
		"code": "GU"
	},
	{
		"name": "Guipúzcoa",
		"code": "SS"
	},
	{
		"name": "Huelva",
		"code": "H"
	},
	{
		"name": "Huesca",
		"code": "HU"
	},
	{
		"name": "Jaén",
		"code": "J"
	},
	{
		"name": "La Rioja",
		"code": "LO"
	},
	{
		"name": "Las Palmas",
		"code": "GC"
	},
	{
		"name": "León",
		"code": "LE"
	},
	{
		"name": "Lleida",
		"code": "L"
	},
	{
		"name": "Lugo",
		"code": "LU"
	},
	{
		"name": "Madrid",
		"code": "M"
	},
	{
		"name": "Murcia",
		"code": "MU"
	},
	{
		"name": "Málaga",
		"code": "MA"
	},
	{
		"name": "Navarra",
		"code": "NA"
	},
	{
		"name": "Ourense",
		"code": "OR"
	},
	{
		"name": "Palencia",
		"code": "P"
	},
	{
		"name": "Pontevedra",
		"code": "PO"
	},
	{
		"name": "Salamanca",
		"code": "SA"
	},
	{
		"name": "Santa Cruz de Tenerife",
		"code": "TF"
	},
	{
		"name": "Segovia",
		"code": "SG"
	},
	{
		"name": "Sevilla",
		"code": "SE"
	},
	{
		"name": "Soria",
		"code": "SO"
	},
	{
		"name": "Tarragona",
		"code": "T"
	},
	{
		"name": "Teruel",
		"code": "TE"
	},
	{
		"name": "Toledo",
		"code": "TO"
	},
	{
		"name": "Valencia",
		"code": "V"
	},
	{
		"name": "Valladolid",
		"code": "VA"
	},
	{
		"name": "Vizcaya",
		"code": "BI"
	},
	{
		"name": "Zamora",
		"code": "ZA"
	},
	{
		"name": "Zaragoza",
		"code": "Z"
	},
	{
		"name": "Álava",
		"code": "VI"
	},
	{
		"name": "Ávila",
		"code": "AV"
	},
	{
		"name": "Ceuta",
		"code": "CE"
	},
	{
		"name": "Melilla",
		"code": "ML"
	},
	{
		"name": "Andalucía",
		"code": "AN"
	},
	{
		"name": "Aragón",
		"code": "AR"
	},
	{
		"name": "Asturias, Principado de",
		"code": "AS"
	},
	{
		"name": "Canarias",
		"code": "CN"
	},
	{
		"name": "Cantabria",
		"code": "CB"
	},
	{
		"name": "Castilla y León",
		"code": "CL"
	},
	{
		"name": "Castilla-La Mancha",
		"code": "CM"
	},
	{
		"name": "Catalunya",
		"code": "CT"
	},
	{
		"name": "Extremadura",
		"code": "EX"
	},
	{
		"name": "Galicia",
		"code": "GA"
	},
	{
		"name": "Illes Balears",
		"code": "IB"
	},
	{
		"name": "La Rioja",
		"code": "RI"
	},
	{
		"name": "Madrid, Comunidad de",
		"code": "MD"
	},
	{
		"name": "Murcia, Región de",
		"code": "MC"
	},
	{
		"name": "Navarra, Comunidad Foral de",
		"code": "NC"
	},
	{
		"name": "País Vasco",
		"code": "PV"
	},
	{
		"name": "Valenciana, Comunidad",
		"code": "VC"
	}
];

const PORTUGAL_STATES = [
  {
		"name": "Aveiro",
		"code": "01"
	},
	{
		"name": "Beja",
		"code": "02"
	},
	{
		"name": "Braga",
		"code": "03"
	},
	{
		"name": "Bragança",
		"code": "04"
	},
	{
		"name": "Castelo Branco",
		"code": "05"
	},
	{
		"name": "Coimbra",
		"code": "06"
	},
	{
		"name": "Faro",
		"code": "08"
	},
	{
		"name": "Guarda",
		"code": "09"
	},
	{
		"name": "Leiria",
		"code": "10"
	},
	{
		"name": "Lisboa",
		"code": "11"
	},
	{
		"name": "Portalegre",
		"code": "12"
	},
	{
		"name": "Porto",
		"code": "13"
	},
	{
		"name": "Região Autónoma da Madeira",
		"code": "30"
	},
	{
		"name": "Região Autónoma dos Açores",
		"code": "20"
	},
	{
		"name": "Santarém",
		"code": "14"
	},
	{
		"name": "Setúbal",
		"code": "15"
	},
	{
		"name": "Viana do Castelo",
		"code": "16"
	},
	{
		"name": "Vila Real",
		"code": "17"
	},
	{
		"name": "Viseu",
		"code": "18"
	},
	{
		"name": "Évora",
		"code": "07"
	}
];

module.exports = {
  US_STATES,
  CANADA_STATES,
  UK_STATES,
  IRELAND_STATES,
  GERMANY_STATES,
  NETHERLANDS_STATES,
  BELGIUM_STATES,
  FRANCE_STATES,
  HUNGARY_STATES,
  SPAIN_STATES,
  PORTUGAL_STATES
};
