<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container">
      <h4 style="margin-bottom: 30px;">My Payment Methods</h4>
      <div style="text-align: center; margin: 20px 0;" v-if="loading"><a-spin /></div>
      <div v-if="loading === false">
        <div class="card" id="add-new-payment-method" style="margin-bottom: 30px;" v-if="is_new_form_visible">
          <div class="card-header">Add New Payment Method</div>
          <div class="card-body">
            <div style="text-align: center; margin: 20px 0;" v-if="form_loading"><a-spin /></div>
            <fieldset>
              <legend>Payment Details</legend>
              <div class="form-group" style="margin-top: 1rem;">
                <div id="card-element" class="MyCardElement"></div>
                <div id="card-errors" role="alert"></div>
              </div>
            </fieldset>
            <fieldset style="margin-top: 20px;">
              <legend>Billing Information</legend>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>First name</label>
                    <input type="text" class="form-control" v-model="billing_address.first_name" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Last name</label>
                    <input type="text" class="form-control" v-model="billing_address.last_name" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="text" class="form-control" v-model="billing_address.email" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Phone</label>
                    <input type="text" class="form-control" v-model="billing_address.phone" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Address</label>
                    <input type="text" class="form-control" v-model="billing_address.address" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>City</label>
                    <input type="text" class="form-control" v-model="billing_address.city" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>State/Region/County</label>
                    <select v-model="billing_address.state" class="form-control">
                      <option :value="state.code" v-for="state in states" :key="state">{{state.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Zip Code</label>
                    <input type="text" class="form-control" v-model="billing_address.zip_code" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Country</label>
                    <select v-model="billing_address.country" class="form-control">
                      <option value="US">United States</option>
                      <option value="CA">Canada</option>
                      <option value="UK">United Kingdom</option>
                      <option value="IE">Ireland</option>
                      <option value="DE">Germany</option>
                      <option value="NL">Netherlands</option>
                      <option value="BE">Belgium</option>
                      <option value="FR">France</option>
                      <option value="HU">Hungary</option>
                      <option value="ES">Spain</option>
                      <option value="PT">Portugal</option>
                    </select>
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="form-group" style="text-align: center; margin-top: 1rem;">
              <button type="button" class="btn btn-primary btn-sm" style="padding: 0.4rem 1rem;" :disabled="form_loading" @click="addPaymentMethod">Add Card</button>
            </div>
          </div>
        </div>

        <div class="card" style="margin-bottom: 30px;" v-if="!is_new_form_visible">
          <div class="card-header">
            <button type="button" class="pull-right btn btn-sm btn-primary" @click="showNewPaymentForm()">Add New Payment Method</button>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item" v-for="payment_method in items" :key="payment_method.id">
              <div>
                <img src="../assets/img/card/amex.png" style="width: 25px" v-if="payment_method.card.brand == 'amex'" />
                <img src="../assets/img/card/diners.png" style="width: 25px" v-if="payment_method.card.brand == 'diners'" />
                <img src="../assets/img/card/discover.png" style="width: 25px" v-if="payment_method.card.brand == 'discover'" />
                <img src="../assets/img/card/jcb.png" style="width: 25px" v-if="payment_method.card.brand == 'jcb'" />
                <img src="../assets/img/card/mastercard.png" style="width: 25px" v-if="payment_method.card.brand == 'mastercard'" />
                <span v-if="payment_method.card.brand == 'unionpay'">UnionPay</span>
                <img src="../assets/img/card/visa.png" style="width: 25px" v-if="payment_method.card.brand == 'visa'" />
                **** **** **** {{ payment_method.card.last4 }} <span class="badge badge-primary" v-if="payment_method.id == customer.invoice_settings.default_payment_method">Default</span>
              </div>
              <div style="margin: 0 0 10px 0; font-size: 13px;">
                Exp.: <span v-if="payment_method.card.exp_month > 9">{{ payment_method.card.exp_month }}</span>
                <span v-if="payment_method.card.exp_month <= 9">0{{ payment_method.card.exp_month }}</span>
                <span>/ {{ payment_method.card.exp_year }}</span>
              </div>
              <div style="font-size: 11px;" v-if="payment_method.id != customer.invoice_settings.default_payment_method">
                <a href="javascript:;" @click="removePaymentMethod(payment_method.id);">Remove</a> - <a href="javascript:;" @click="setDefaultPaymentMethod(payment_method.id);">Make Default</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import axios from 'axios';
import ASpin from 'ant-design-vue/lib/spin';
import 'ant-design-vue/lib/spin/style/index.css';

import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';
import Swal from "sweetalert2";
import payment_states from '@/constants/payment_states';

export default {
  components: {
    BaseLayout,
    SubHeader,
    ASpin
  },
  data() {
    return {
      states: payment_states.US_STATES,
      loading: false,
      form_loading: false,
      customer: null,
      is_new_form_visible: false,
      items: [],
      stripe_public_key: process.env.VUE_APP_STRIPE_KEY,
      stripe: null,
      elements: null,
      card: null,
      billing_address: {
        first_name: null,
        last_name: null,
        email: this.$store.state.user.email,
        phone: this.$store.state.user.phoneNumber,
        address: null,
        city: null,
        state: 'AL',
        zip_code: null,
        country: 'US'
      },
    }
  },
  watch: {
    'billing_address.country'(newValue) {
      if (newValue === 'US') {
        this.states = payment_states.US_STATES;
        this.billing_address.state = payment_states.US_STATES[0].code;
      } else if (newValue === 'CA') {
        this.states = payment_states.CANADA_STATES;
        this.billing_address.state = payment_states.CANADA_STATES[0].code;
      } else if (newValue === 'UK') {
        this.states = payment_states.UK_STATES;
        this.billing_address.state = payment_states.UK_STATES[0].code;
      } else if (newValue === 'IE') {
        this.states = payment_states.IRELAND_STATES;
        this.billing_address.state = payment_states.IRELAND_STATES[0].code;
      } else if (newValue === 'DE') {
        this.states = payment_states.GERMANY_STATES;
        this.billing_address.state = payment_states.GERMANY_STATES[0].code;
      } else if (newValue === 'NL') {
        this.states = payment_states.NETHERLANDS_STATES;
        this.billing_address.state = payment_states.NETHERLANDS_STATES[0].code;
      } else if (newValue === 'BE') {
        this.states = payment_states.BELGIUM_STATES;
        this.billing_address.state = payment_states.BELGIUM_STATES[0].code;
      } else if (newValue === 'FR') {
        this.states = payment_states.FRANCE_STATES;
        this.billing_address.state = payment_states.FRANCE_STATES[0].code;
      } else if (newValue === 'HU') {
        this.states = payment_states.HUNGARY_STATES;
        this.billing_address.state = payment_states.HUNGARY_STATES[0].code;
      } else if (newValue === 'ES') {
        this.states = payment_states.SPAIN_STATES;
        this.billing_address.state = payment_states.SPAIN_STATES[0].code;
      } else if (newValue === 'PT') {
        this.states = payment_states.PORTUGAL_STATES;
        this.billing_address.state = payment_states.PORTUGAL_STATES[0].code;
      } else {
        this.states = [{ code: '-', name: '-' }];
        this.billing_address.state = '-';
      }
    }
  },
  async mounted() {
    document.title = 'My Payment Methods - Mane Street Market';
    window.scrollTo(0, 0);
    await this.loadItems();
  },
  methods: {
    async loadItems() {
      this.loading = true;
      try {
        let response = await axios.get('/api/my-payment-methods');
        this.items = response.data.payment_methods;
        this.customer = response.data.customer;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    includeStripe( URL, callback ){
      let documentTag = document, tag = 'script',
          object = documentTag.createElement(tag),
          scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = '//' + URL;
      if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    configureStripe() {
      this.stripe = Stripe(this.stripe_public_key);
      this.elements = this.stripe.elements();
      this.card = this.elements.create('card', {
        hidePostalCode: true
      });
      this.card.mount('#card-element');
      this.form_loading = false;
    },
    showNewPaymentForm() {
      let $this = this;
      this.form_loading = true;
      this.is_new_form_visible = true;
      this.includeStripe('js.stripe.com/v3/', () => {
        $this.configureStripe();
      });
    },
    async removePaymentMethod(id) {
      if (confirm('Are you sure?') === false) {
        return;
      }
      this.loading = true;
      try {
        await axios.delete('/api/my-payment-methods/' + id);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadItems();
      }
    },
    async setDefaultPaymentMethod(id) {
      this.loading = true;
      try {
        await axios.post('/api/my-payment-methods/' + id + '/set-default');
      } catch (error) {
        console.log(error);
      } finally {
        this.loadItems();
      }
    },
    async addPaymentMethod() {
      this.form_loading = true;
      try {
        let result = await this.stripe.createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: {
            name: this.billing_address.first_name + ' ' + this.billing_address.last_name,
            address: {
              city: this.billing_address.city,
              country: this.billing_address.country,
              line1: this.billing_address.address,
              postal_code: this.billing_address.zip_code,
              state: this.billing_address.state
            },
            email: this.billing_address.email,
            phone: this.billing_address.phone
          }
        });
        if (result.paymentMethod) {
          await axios.post('/api/my-payment-methods/' + result.paymentMethod.id);
          this.is_new_form_visible = false;
          this.loadItems();
        } else {
          Swal.fire({
            title: 'Error adding payment method',
            text: 'Please fill billing address form!'
          });
          console.log(result.error);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.form_loading = false;
      }
    }
  }
}
</script>